/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.MuiStepper-root {
    width: unset; 
    -ms-flex-item-align: center; 
        -ms-grid-row-align: center; 
        align-self: center;
    margin-bottom: 2em;
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

#select-language{
    display: flex;
    align-items: center;
    justify-content: center;
}

#select-language select{
    font-size: 14px;
}

.SEcheckbox{
    width: 92.5% !important;
}

.MuiStep-root.MuiStep-horizontal {
    width: 5%;
    padding: 0;
}

.MuiStepConnector-root {
    top: .6em !important;
    right: unset  !important;
    left: -13.5vw !important;
    width: 27vw;
    height: 4px;
    background: #dbdbdb;
    border: none;
    z-index: 1;
}

.MuiStepConnector-alternativeLabel.Mui-completed {
    background: #C87F50;
}

.MuiStepConnector-alternativeLabel.Mui-active:after {
    position: absolute;
    content: '';
    margin-top:-1px;
    height: 4px;
    width: 100%;
    background: #C87F50;
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
    -webkit-animation: trans .3s linear;
            animation: trans .3s linear;
}

@-webkit-keyframes trans {

    0% {
        width: 0%;

    }

    100% {
        width: 100%;

    }

}

@keyframes trans {

    0% {
        width: 0%;

    }

    100% {
        width: 100%;

    }

}

.step-widget {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #ffffffd7;
    border-radius: 5px;
    padding: 2em;
    height: 100%;
    margin: 0 0 10vw 0;
    width: 90%;
    overflow: hidden;
    z-index: 3;
}

.step-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #C87F50;
    width: 40em;
    border-radius:5px;
    line-height: 20px;
    margin: 10px auto 40px;
    padding: 15px 25px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}

.step-btn:hover {
    background: #3c3c3c;
    padding: 15px 25px;
}

.noactive-step-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #C87F50;
    width: 40em;
    border-radius:5px;
    line-height: 20px;
    margin: 10px auto 40px;
    padding: 15px 25px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    cursor: default;
}

.noactive-step-btn:hover {
    background: #C87F50;
    padding: 15px 25px;
}

.arrow- {
    width: 19px;
    height: 19px;
    fill: #fff;
    margin: 0 .5em;
}

.arrow-sec {
    width: 19px;
    height: 19px;
    fill: #000000;
    margin: 0 0.5em;
}

.canvas_box div {
    height: 20em !important;
}

.canvas_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: url('../../graphic_schema/houses__.webp');
    background-size: 290%;
    width: 15em;
    height: 15em;
}

.MuiStep-horizontal {
    padding-right: unset;
}

.container-for-stepper {
    width: 100vw;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.step-widget h1 {
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    margin: .6em 0; 
}

.step-widget label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 15px 20px;
}

.mapouter label {
    display: flex;
    flex-flow: column nowrap;
    margin: 25px 0 !important;
}

.step-widget label span {
    z-index: 2;
    height: 20px;
    line-height: 20px;
    background: #fff;
    width: auto;
    padding: 0 6px;
    font-weight: 400;
    color: rgba(0,0,0,0.45);
    border-radius: 4px;
}


/* first step */

.mapouter {
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 1em;
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.mapouter label {
    width: 42%;
}

.mapouter label div {
    width: 100%;
}

.mapouter div {
    width: 42%;
    border-radius: 5px;
}

/* first step */

/* second & third step */

#user-info {
    width: 100%;
}

#user-info label {
    width: 100%;
}

.widget-form  {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 1em 0;
}

.additional-information {
    position: relative;
    left: -1.1rem;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    width: 100%;
    margin: 2em 0;
}

.additional-information div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: end;
    margin: .5em;
    width: 100%;
}

.additional-information div:first-child {
    height: 70vw;
}

.widget-form h3 {
    margin: .3em 0;
}




/* second & third step */

/* configutator */

#content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    background: white;
    padding: 1em;
    width: 100em;
    z-index: 999;
}

#content span{
    color: black;
}

#content h1{
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
}

#map * {
    overflow:visible;
}

div .checkbox{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}

div .checkbox input{
    position: relative;
    left: -250px;
    width: 10px;
}

div .checkbox span{
    position: relative;
    top: 11px;
    left: -220px;
    width: 90%;
}

#uploadBtn{
    position: relative;
    left: -305px !important;
}

.privacyPolicy{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100% !important;
    height: 50px !important;
}
.SEcheckbox{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
            justify-content: center;
}

.mapouter label {
    width: 30%;
}

/* configutator */

@media screen and (max-width: 1600px) {
    .mapouter label {
        width: 42%;
    }

    #file{
        position: relative;
        left: -3.5rem !important;
    }

    div .checkbox{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
    }
    
    div .checkbox input{
        position: relative;
        left: -100px;
        width: 10px;
    }
    
    div .checkbox span{
        position: relative;
        top: 11px;
        left: -80px;
        width: 100%;
    }

    #uploadBtn{
        position: relative;
        left: -200px !important;
    }
} 

@media screen and (max-width: 1500px) {
    #uploadBtn{
        position: relative;
        left: -180px !important;
    }
}

@media screen and (max-width: 1366px) {
    #uploadBtn{
        position: relative;
        left: -180px !important;
    }
}

@media screen and (max-width: 1200px) {
    div .checkbox{
        position: relative;
        left: 5.8rem;
    }

    .SEcheckbox{
        position: relative;
        left: 0rem;
    }
}

@media screen and (max-width: 850px) {

    .mapouter label {
        width: 80%;
    }

    div .checkbox{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        position: relative;
        left: -1rem;
    }
    
    div .checkbox input{
        position: relative;
        left: 10px;
        width: 10px;
    }
    
    div .checkbox span{
        position: relative;
        top: 11px;
        left: 20px;
        width: 90%;
    }

    #uploadBtn{
        position: relative;
        left: 0px !important;
    }

    .privacyPolicy{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100% !important;
        height: 50px !important;
    }

    .SEcheckbox{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row nowrap;
                flex-flow: row nowrap;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }
} 