/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
/* components */
@import url("./components/widgetform.scss");
/* components */
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-size: unset !important;
}

.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #ffff;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
}

.noDisplay {
  display: none;
}

h1 {
  font-family: Roboto,Helvetica Neue,sans-serif;
  font-size: 24px;
}

h2 {
  font-family: Roboto,Helvetica Neue,sans-serif;
  font-size: large;
}

h3 {
  font-family: Roboto,Helvetica Neue,sans-serif;
  font-size: xx-large;
}

p {
  font-family: Roboto,Helvetica Neue,sans-serif;
  font-size: 13px;
}

span {
  font-family: Roboto,Helvetica Neue,sans-serif;
  font-size: x-large;
}

ul {
  list-style: none;
}

li {
  font-family: Roboto,Helvetica Neue,sans-serif;
  text-decoration: none;
}

a {
  text-decoration: none;
}

.widget-image {
  margin-left: 1em;
  margin-bottom: 1em;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  height: 15em;
}

.higher-img {
  max-height: 20vw;
}

label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-family: Roboto,Helvetica Neue,sans-serif;
  font-weight: 500;
  font-size: 16px;
  margin: .25em;
  width: 100%;
}

span {
  font-family: Roboto,Helvetica Neue,sans-serif;
  font-size: 14px;
  line-height: 28px;
}

button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1em;
  margin: .5em;
  background: #ededed;
  color: white;
  border: none;
  cursor: pointer;
}

button span {
  font-size: 18px;
}

.medium-round-btn {
  padding: 8px 15px;
  margin: 0 20px 0 0px;
  border-radius: 5px;
  color: black;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.medium-round-btn span {
  font-size: 14px;
}

.blacker {
  background: #3c3c3c;
  color: white;
}

.MuiSvgIcon-root {
  background: #fff !important;
  border: 2px solid #dbdbdb !important;
  border-radius: 100% !important;
  z-index: 2 !important;
  color: white;
  -webkit-transition: opacity 0.3s ease !important;
  -o-transition: opacity 0.3s ease !important;
  transition: opacity 0.3s ease !important;
}

.MuiStepIcon-root.Mui-completed {
  color: #fff !important;
  border: 5px solid #C87F50 !important;
}

.Mui-active circle {
  color: white !important;
}

.Mui-active svg.MuiSvgIcon-root {
  border: 5px solid #C87F50 !important;
}

.MuiStepIcon-text {
  fill: white;
}

.icon-bar {
  margin-top: -8px;
  height: 2em;
}

.image {
  width: 50%;
}

.MuiSvgIcon-root circle {
  color: white;
  margin: 12px !important;
}

input[type='text'], input[type='date'], input[type='number'], input[type='email'], input[type='tel'], select, textarea {
  border: 1px solid #dbdbdb;
  background: #ffff;
  border-radius: 3px;
  height: 40px;
  line-height: 28px;
  font-family: Roboto,Helvetica Neue,sans-serif;
  font-size: 13px;
  font-weight: 400;
  outline: none;
  padding: 0 5px;
  width: 100%;
  color: #3c3c3c;
}

.checkbox {
  width: 100%;
  height: 100px !important;
  margin-bottom: -100px;
}

.checkbox span {
  position: relative;
  left: 24rem;
}

div .privacyPolicy {
  width: 100%;
  height: 50px !important;
}

.chx {
  position: relative;
  top: -0.45rem;
  left: -17.5rem;
  margin-bottom: -50px;
}

#file-upload-button {
  width: 213px;
  height: 41px;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  color: white !important;
}

#user-info select {
  width: unset !important;
}

textarea {
  height: unset !important;
  width: 70%;
  padding: 10px;
}

header {
  position: relative !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  background: #fff !important;
  margin-top: unset !important;
  margin-bottom: unset !important;
  border-bottom: 1px solid #dbdbdb !important;
  z-index: 4 !important;
}

.header-inner-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-right: 1px solid #cfcfcf !important;
  padding: 1em;
}

#uploadBtn {
  display: inline-block;
  background-color: #ededed;
  position: relative;
  left: -13.5rem;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.2rem;
  cursor: pointer;
  margin-top: 1rem;
  width: 213px;
  height: 40px;
  -webkit-transition: all .15s ease;
  -o-transition: all .15s ease;
  transition: all .15s ease;
}

#uploadBtn p {
  position: relative;
  top: -1.4rem;
  left: 4rem;
  font-size: 16px;
  color: #3c3c3c;
}

.uploadIcon {
  position: relative;
  top: 0.1rem;
  left: 2rem;
  width: 20px;
  height: 20px;
  color: #3c3c3c;
  -webkit-transition: all .15s ease;
  -o-transition: all .15s ease;
  transition: all .15s ease;
}

#uploadBtn:hover {
  background-color: #3c3c3c;
}

#uploadBtn:hover .uploadIcon {
  color: #fff;
}

#uploadBtn p {
  -webkit-transition: all .15s ease;
  -o-transition: all .15s ease;
  transition: all .15s ease;
}

#uploadBtn:hover p {
  color: #fff;
}

#file {
  position: relative;
  top: -2.8rem;
  left: -10rem;
  width: 100px;
  height: 20px;
}

#uploadBtn .position {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  color: #3c3c3c;
}

.header-inner-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.header-logo, .header-logo img {
  height: 65px;
}

header ul {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

header li {
  color: white;
  text-decoration: none;
  margin: 0 1em;
}

.container-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}

form .container-row {
  margin-top: 1em;
}

footer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  z-index: 3;
}

@media (max-width: 480px) {
  #file {
    position: relative;
    top: -0.5rem;
    left: -1rem !important;
  }
}

@media (max-width: 339px) {
  header {
    width: 100%;
  }
  .header-inner-left {
    width: 100%;
    border-right: unset !important;
    border-bottom: 1px solid #cfcfcf !important;
  }
  .header-inner-right {
    padding: .5em;
  }
}

@media (min-width: 699px) {
  .MuiStepper-root {
    width: 100%;
  }
  .MuiStepConnector-root {
    right: unset  !important;
    left: -16vw !important;
    width: 32vw;
  }
  .mapouter label {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .widget-form {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .additional-information {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media (min-width: 1199px) {
  #user-info {
    width: 60%;
  }
  .additional-information {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 50%;
  }
  .additional-information div {
    width: 340px;
    height: 340px;
  }
}
